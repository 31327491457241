.swiper {
  padding: 2rem;
}

.swiper .res-slide {
  min-width: 300px;
  max-width: 400px;
  min-height: 350px;
  border: 2px solid hsl(210, 100%, 50%);
  border-radius: 20px;
}

.swiper-nav-btns button {
  width: 60px;
  height: 50px;
  border-radius: 50px;
  border: none;
  outline: none;
  background-color: hsl(210, 100%, 50%);
  color: #fff;
  margin: 0px 0.5rem;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  margin-top: 30px;
  transition: all 0.5s;
}

.swiper-nav-btns button:hover {
  background-color: hsl(210, 100%, 40%);
  cursor: pointer;
}
